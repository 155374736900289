import styled from "styled-components";

import BGDotTexture from "assets/images/bg-dot-texture.svg";

const About = styled.div`
  padding: 10em calc(15vw / 2) 7em;
  display: flex;
  flex-direction: column;

  > *:not(last-child) {
    margin-bottom: 20px;
  }

  background-image: url(${BGDotTexture}),
    linear-gradient(72deg, #1f2058 65%, #00033f);
  background-position: 0px 0px, 0px 0px;
  background-size: auto, auto;

  h4 {
    font-size: 18px;
    color: white;
  }

  a {
    font-size: 18px;
    text-decoration: underline;
    text-transform: uppercase;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > *:not(last-child) {
      margin-bottom: 2em;
    }

    & > div:first-child {
      display: flex;
      flex-direction: column;

      p {
        font-size: 17px;
        line-height: 34px;
      }
    }
  }
  @media (min-width: 1024px) {
    padding: 10em calc(15vw / 2) 10em;
    padding-right: 20%;
  }
`;

export default About;
