import styled from "styled-components";

const International = styled.div`
  padding: 7em calc(15vw / 2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 60px;
  background-image: linear-gradient(180deg, #e5e5e5, #fff);
  h1 {
    color: rgb(0, 3, 63);
    font-size: 50px;
    line-height: 70px;
    max-width: 450px;
  }
  p {
    color: rgb(0, 3, 63);
    font-size: 20px;
    line-height: 34px;
    max-width: 600px;
  }
  & > div:last-child {
    width: 80%;
    align-self: center;
  }
  @media (min-width: 850px) {
    padding: 11em calc(15vw / 2);
    flex-direction: row;
    & > div:last-child {
      width: 50%;
    }
  }
  @media (min-width: 1250px) {
    div {
      display: flex;
      flex-direction: column;
      gap: 60px;
    }
    p {
      font-size: 27px;
    }
    h1 {
      font-size: 60px;
    }
  }
`;
export default International;
