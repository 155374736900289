import React from "react";

import { graphql } from "gatsby";

import { useTranslation } from "gatsby-plugin-react-i18next";

import Accenture from "assets/images/accenture.png";
import AWS from "assets/images/aws.png";
import Dauphine from "assets/images/dauphine.png";
import IBA from "assets/images/iba_white.png";
import PWC from "assets/images/logo_pwc_white.png";
import MappyBoy from "assets/images/mappy-boi.svg";
import Microsoft from "assets/images/microsoft.png";
// import Plateform58 from "assets/images/plateforme58.png";
// import PlugAndPlay from "assets/images/plug-and-play.png";
import Polytechnic from "assets/images/polytechnic.png";
// import Sia from "assets/images/sia_partner_white.png";
// import StationF from "assets/images/station-f.svg";
// import Wilco from "assets/images/wilco_logo_white.png";
import About from "components/About";
import CallToAction from "components/CallToAction";
import Divider from "components/Divider";
import International from "components/International";
import PageContainer from "components/PageContainer";
import Partners from "components/Partners";
import PartnersTwoColumns from "components/PartnersTwoColumns";
import SEO from "components/SEO";

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <About>
        <div>
          <Divider icon={<h4>{t("About")}</h4>} color="white" />
        </div>
        <div>
          <div>
            <p>
              {t(
                "Qantev develops artificial intelligence for health insurance operations. From network to case management, our technology provides critical insights to ops teams. Qantev makes a difference by helping insurers deliver higher quality services with more affordable premiums to hundreds of thousands of members around the world."
              )}
            </p>
            <p>
              {t(
                "We were cofounded in Paris in 2019 by machine learning and mathematics experts with background in insurance and finance, motivated to take breakthroughs in AI and mathematical optimization into the real world."
              )}
            </p>
            <p>
              {t(
                "We have since grown our team and successfully secured financing from leading VC funds, meanwhile industry leaders joined our board to bring the next generation of enterprise software platform to insurers."
              )}
            </p>
          </div>
          <div>
            <a href="https://qantev.welcomekit.co/">
              {t("Check out our vacancies")} &gt;
            </a>
          </div>
        </div>
      </About>
      <International>
        <div>
          <h1>{t("International Work")}</h1>
          <p>
            {t(
              "Our software is already being used by leading health insurance brands in multiple countries."
            )}
          </p>
        </div>
        <div>
          <img src={MappyBoy} alt="mappy-boi" />
        </div>
      </International>
      <Partners
        style={{ backgroundImage: "linear-gradient(135deg,#00033f,#373991)" }}
      >
        <PartnersTwoColumns>
          <div>
            <h1>{t("Business Partners")}</h1>
            <Partners.Logos>
              <img src={PWC} alt="pwc" className="small" />
              <img src={Microsoft} alt="microsoft" />
              <img src={AWS} alt="aws" className="small" />
              <img src={Accenture} alt="accenture" />
              <img src={IBA} alt="iba" />
            </Partners.Logos>
          </div>
          <div style={{ border: "1px solid #fff" }} />
          <div>
            <h1>{t("Research Partners")}</h1>
            <Partners.Logos>
              <img src={Dauphine} alt="dauphine" />
              <img
                src={Polytechnic}
                alt="polytechnic"
                style={{ width: "100px" }}
              />
            </Partners.Logos>
          </div>
        </PartnersTwoColumns>
      </Partners>
      <CallToAction />
    </PageContainer>
  );
};

export default AboutPage;

// eslint-disable-next-line react/prop-types
export const Head = ({ location: { pathname } }) => (
  <SEO title="About" description="All about Qantev" pathname={pathname} />
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["about", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
