import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

const PartnersLogosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > *:not(:last-child) {
    margin-bottom: 4em;
  }

  img {
    width: 200px;
  }

  img.small {
    width: 150px;
  }

  @media (min-width: 600px) {
    flex-direction: column;

    img {
      width: 250px;
    }
    .small {
      width: 150px;
    }
    .big {
      width: 300px;
    }
  }
`;

const PartnerRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > *:not(:last-child) {
    margin-bottom: 4em;
  }

  @media (min-width: 600px) {
    flex-direction: row;

    > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: 4em;
    }
  }
`;

const PartnersLogosWrapperRow = styled.div`
  width: 100%;
  display: grid;
  gap: 4em;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-items: center;
  align-items: center;

  img {
    width: 250px;
  }

  @media (min-width: 1380px) {
    img:last-child {
      justify-self: flex-start;
    }

    img:first-child {
      justify-self: flex-end;
    }
  }

  img.small {
    width: 150px;
  }
`;

const PartnersLogos = ({ children }) => {
  const iterPair = (iterable) => {
    const rows = [];
    for (let i = 0; i < iterable.length - 1; i += 2) {
      rows.push([iterable[i], iterable[i + 1]]);
    }
    if (iterable.length % 2 !== 0) {
      rows.push([iterable[iterable.length - 1]]);
    }
    return rows;
  };

  return (
    <PartnersLogosWrapper>
      {children &&
        iterPair(children).map((row) => (
          <PartnerRowWrapper>{row}</PartnerRowWrapper>
        ))}
    </PartnersLogosWrapper>
  );
};

PartnersLogos.propTypes = {
  children: PropTypes.node,
};

PartnersLogos.defaultProps = {
  children: null,
};

const Partners = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6em 2em;
  background-image: linear-gradient(135deg, #00033f, #1f2058);
  text-align: center;

  > *:not(:last-child) {
    margin-bottom: 2em;
  }

  h2 {
    color: #fff;
    font-size: 38px;
    line-height: 49px;
    font-weight: 600;
  }

  @media (min-width: 850px) {
    h2 {
      font-size: 48px;
    }
  }
`;

Partners.Logos = PartnersLogos;
Partners.LogosRow = PartnersLogosWrapperRow;

export default Partners;
