import styled from "styled-components";

export default styled.div`
  display: grid;
  justify-content: space-between;
  gap: 4em;

  h1 {
    margin-bottom: 2em;
  }

  > div {
    text-align: center;
  }

  @media (min-width: 1024px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1px 1fr;
  }
`;
